html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#app {
    height: 100%;
}

::-webkit-scrollbar-thumb {
    border-width: 10px 10px 10px 10px;
    border-color: rgba(26, 18, 74, 0.51);
    background-color: rgba(26, 18, 74, 0.27);
}

::-webkit-scrollbar-thumb:hover {
    border-width: 1px 1px 1px 2px;
    border-color: rgba(248, 172, 0, 0.7);
    background-color: #404d6a;
}

::-webkit-scrollbar-thumb:active {
    border-width: 1px 1px 1px 2px;
    border-color: rgba(248, 172, 0, 0.7);
    background-color: rgb(248, 172, 0);
}

::-webkit-scrollbar-track {
    border-width: 0;
}

::-webkit-scrollbar-track:hover {
    /*border-left: solid 1px #eaeaea;*/
    background-color: rgba(66, 58, 200, 0.06);
}


.disableCaret {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;
}